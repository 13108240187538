import React from 'react';
import './Hero.css';
import SignupForm from './SignupForm';

const Hero = () => {
    return (
        <header className="hero">
            <div className="hero-content">
                <div className="headline-width">
                    <h1 className="hero-title">Transform Ideas into Social Media <span className="gold">Gold</span> - with AI alchemy</h1>
                </div>
                <div className="subheadline-width">
                    <p className="hero-subtitle">Brandchaimy uses advanced AI to help small businesses create engaging Social Media posts effortlessly. Turn your creative sparks into captivating content that drives results.</p>
                </div>
                <div className="cta-width">
                    <SignupForm />
                </div>
            </div>
        </header>
    );
}

export default Hero;