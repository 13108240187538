import React from 'react';
import './Navbar.css';
import logo from '../assets/logo.png';

const Navbar = () => {
    return (
        <nav className="navbar">
            <a href="/" className="logo">
                <img src={logo} alt="brandchaimy logo" />
            </a>
        </nav>
    );
}

export default Navbar;