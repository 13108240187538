// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.signup-form input[type="email"] {
    padding: 10px;
    font-size: 1em;
    margin-bottom: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
}

.signup-form button {
    padding: 10px 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    background: #ff6600;
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
    border-radius: 5px;
    transition: background 0.3s;
    border: none;
    cursor: pointer;
    width: 50%;
    max-width: 300px;
}

.signup-form button:hover {
    background: #FFD700;
}

.error {
    background-color: #F5F7FA;
    color: red;
    font-size: 3vw;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SignupForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,qBAAqB;IACrB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,iCAAiC;IACjC,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,2BAA2B;IAC3B,YAAY;IACZ,eAAe;IACf,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,qBAAqB;IACrB,aAAa;AACjB","sourcesContent":[".signup-form {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.signup-form input[type=\"email\"] {\n    padding: 10px;\n    font-size: 1em;\n    margin-bottom: 1.2rem;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    width: 100%;\n    max-width: 400px;\n}\n\n.signup-form button {\n    padding: 10px 20px;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 700;\n    background: #ff6600;\n    color: #fff;\n    text-decoration: none;\n    font-size: 1.2em;\n    border-radius: 5px;\n    transition: background 0.3s;\n    border: none;\n    cursor: pointer;\n    width: 50%;\n    max-width: 300px;\n}\n\n.signup-form button:hover {\n    background: #FFD700;\n}\n\n.error {\n    background-color: #F5F7FA;\n    color: red;\n    font-size: 3vw;\n    margin-top: 1.5rem;\n    margin-bottom: 1.5rem;\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
