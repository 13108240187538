import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import './App.css';

const App = () => {
    return (
        <div className="App">
          <div className="navbar-container dark-accent">
            <Navbar />
          </div>
          <div className="hero-container dark-accent">
            <Hero />
          </div>
        </div>
    );
}

export default App;
