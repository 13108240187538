import React, { useState } from 'react';
import axios from 'axios';
import './SignupForm.css';

const SignupForm = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state


    // Email validation function
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    // Sanitize email function
    const sanitizeEmail = (email) => {
        return email.trim();
    };

    // Reset email and error fields
    const resetFields = () => {
        setEmail('');
        setError('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const sanitizedEmail = sanitizeEmail(email);

        if (!validateEmail(sanitizedEmail)) {
            setError('Please enter a valid email address.');
            setTimeout(() => {
                resetFields();
            }, 1000);
            return;
        }

        setLoading(true); // Disable the button when the request starts

        try {
            const apiUrl = process.env.REACT_APP_API_URL || '';
            await axios.post(`${apiUrl}/api/signup`, { email: sanitizedEmail });
            alert('You have signed up successfully!');
            resetFields();
        } catch (error) {
            setError('There was an error signing up. Please try again.');
            // Reset email field after 1 seconds
            setTimeout(() => {
                resetFields();
            }, 1000);
        } finally {
            setLoading(false); // Enable the button when the request finishes
        }
    };

    return (
        <form onSubmit={handleSubmit} className="signup-form">
            <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            {error && <p className="error">{error}</p>}
            <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : 'Be an Alpha Tester'}
            </button>
        </form>
    );
}

export default SignupForm;