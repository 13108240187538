// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/hero-image80.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: 60vw;
    background-position: right 0px top 5vh;
    background-repeat: no-repeat;
    color: #fff;
    text-align: left;
    padding-bottom: 100vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    overflow: hidden;
}

.hero-content {
    padding-top: 6.5rem;
    padding-bottom: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.headline-width {
    max-width: 60vw;
    margin-bottom: 2rem;
}

.hero-title {
    font-size: 6vw;
}

.gold {
    color: #FFD700;
}

.subheadline-width {
    max-width: 38vw;
    margin-bottom: 2rem;
}

.cta-width {
    max-width: 38vw;
    margin-top: 2rem;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/Hero.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;IACrB,sCAAsC;IACtC,4BAA4B;IAC5B,WAAW;IACX,gBAAgB;IAChB,qBAAqB;IACrB,yDAAmD;IACnD,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".hero {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    background-size: 60vw;\n    background-position: right 0px top 5vh;\n    background-repeat: no-repeat;\n    color: #fff;\n    text-align: left;\n    padding-bottom: 100vh;\n    background-image: url('../assets/hero-image80.png');\n    overflow: hidden;\n}\n\n.hero-content {\n    padding-top: 6.5rem;\n    padding-bottom: 0;\n    width: 90%;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.headline-width {\n    max-width: 60vw;\n    margin-bottom: 2rem;\n}\n\n.hero-title {\n    font-size: 6vw;\n}\n\n.gold {\n    color: #FFD700;\n}\n\n.subheadline-width {\n    max-width: 38vw;\n    margin-bottom: 2rem;\n}\n\n.cta-width {\n    max-width: 38vw;\n    margin-top: 2rem;\n}\n\nh1 {\n    font-family: 'Montserrat', sans-serif;\n    font-weight: 700;\n    line-height: 1.1;\n    margin-bottom: 0;\n    margin-top: 0;\n}\n\n.hero p {\n    font-size: 1.2rem;\n    margin-bottom: 1rem;\n    line-height: 1.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
