// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    padding-bottom: 0;
}

.navbar .logo img {
    height: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    width: 90%;\n    margin-left: auto;\n    margin-right: auto;\n    padding-top: 2rem;\n    padding-bottom: 0;\n}\n\n.navbar .logo img {\n    height: 3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
