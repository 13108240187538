// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  color: #333;
  background-color: #F5F7FA;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

.App {
  width: 100%;
  position: relative;
}

.navbar-container {
  width: 100%;
}

.dark-accent {
  background-color: #15126f;
}

.main-bgcolor {
  background-color: #3D52A0; 
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,+BAA+B;EAC/B,WAAW;EACX,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Lato', sans-serif;\n  color: #333;\n  background-color: #F5F7FA;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.6;\n}\n\n.App {\n  width: 100%;\n  position: relative;\n}\n\n.navbar-container {\n  width: 100%;\n}\n\n.dark-accent {\n  background-color: #15126f;\n}\n\n.main-bgcolor {\n  background-color: #3D52A0; \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
